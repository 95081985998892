import { Heading } from "grommet";
import React, { useEffect, useState } from "react";
import "../assets/styles/styles.scss";
import DateOfBirth from "../components/DateOfBirth";
import FormBlock from "../components/FormBlock";
import TextArea from "../components/TextArea";
import RadioInput from "../components/RadioInput";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";

function ReferencesSelect({ name, label, control, setValue }) {
  const defaultValue = useSelector((state) => state.form.other_clubs);
  const [show, setShow] = useState(false);

  console.log(name, defaultValue);

  const clubmembership = useWatch({
    control,
    name: "other_clubs", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue
  });

  useEffect(() => {
    if (clubmembership === "Yes") {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [clubmembership]);

  return (
    <>
      <Heading fill level={2}>
        References
      </Heading>

      <FormBlock title={"Are you a member of any other clubs?"}>
        <RadioInput
          name={"other_clubs"}
          setValue={setValue}
          control={control}
          options={["Yes", "No"]}
          direction="row"
        />
      </FormBlock>

      {show && (
        <FormBlock title={"Please enter the name of the clubs:"}>
          <TextArea
            control={control}
            name="clubs"
            label={"Club Names"}
            info="Please separate club names with commas"
          />
        </FormBlock>
      )}
    </>
  );
}

export default ReferencesSelect;
