import { Box, Grommet, Image, Paragraph, ResponsiveContext } from "grommet";
import { grommet } from "grommet/themes";
import { deepMerge } from "grommet/utils";
import React, { useContext, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import cluboutline from "../assets/images/club_outline@2x.png";
import ForwardIcon from "../assets/images/ic_arrow_forward.inline.svg";
import DownloadIcon from "../assets/images/ic_download.inline.svg";
import toucan from "../assets/images/toucan@2x.png";
import "../assets/styles/styles.scss";
import Button from "../components/Button";
import FormBlock from "../components/FormBlock";
import Footer from "../components/Footer";
import HorizontalRule from "../components/HorizontalRule";
import PageTitle from "../components/PageTitle";
import BusinessInformation from "../formBlocks/businessInformation";
import ChildrenInformation from "../formBlocks/childrenInformation";
import PersonalInformation from "../formBlocks/personalInformation";
import ReferencesSelect from "../formBlocks/referencesSelect";
import InterestSelect from "../formBlocks/interestSelect";
import PageHeader from "../components/PageHeader";
import BackButton from "../components/BackButton";

import { useSelector, useDispatch } from "react-redux";
import { setStage, goBack } from "../state/globalSlice";
import { saveForm } from "../state/formSlice";

function Layout() {
  const stage = useSelector((state) => state.global.stage);
  const dispatch = useDispatch();

  const size = useContext(ResponsiveContext);
  const isMobile = size === "small" || size === "xsmall" ? true : false;

  const {
    handleSubmit,
    control,
    formState,
    setError,
    getValues,
    setValue,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    // const { touchedFields, isValid } = formState;
    // if (typeof touchedFields?.dateOfBirth === "undefined") {
    //   setError("dateOfBirth", { type: "required" }, { shouldFocus: true });
    // } else {
    //   //Submit form
    //   console.log("data", data);
    // }
    dispatch(setStage(2));
  };

  const pbSame = useWatch({
    control,
    name: "interests",
  });
  
  useEffect(()=>{
    console.log(pbSame)
  },[pbSame])

  const formSubmit = () => {
    const formValues = getValues();
    dispatch(saveForm(formValues));
    handleSubmit(onSubmit)();
  };

  return (
    <Box fill="horizontal">
      <PageHeader activeSection={2} />
      <Box
        width={{ max: "952px" }}
        fill="horizontal"
        alignSelf="center"
        align="center"
        pad={{
          horizontal: isMobile ? "24px" : "36px",
          top: isMobile ? "104px" : "250px",
        }}
        animation="fadeIn">
        <PageTitle
          isMobile={isMobile}
          title={`References &\ninterests`}
          image={toucan}
        />

        <HorizontalRule />
        <Box fill>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ReferencesSelect control={control} setValue={setValue} />
            <HorizontalRule />
            <InterestSelect control={control} setValue={setValue} />

            <FormBlock margin={{ top: "35px" }} backButton>
              <Box direction="row" gap="medium">
                {isMobile && <BackButton onClick={() => dispatch(goBack())} />}
                <Button
                  onClick={formSubmit}
                  color={"red"}
                  text={"Next Step"}
                  icon={<ForwardIcon />}
                />
              </Box>
            </FormBlock>
            {/* <input type="submit" value="submit" /> */}
          </form>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Layout;
