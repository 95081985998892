import { Heading } from "grommet";
import React from "react";
import "../assets/styles/styles.scss";
import DateOfBirth from "../components/DateOfBirth";
import FormBlock from "../components/FormBlock";
import TextArea from "../components/TextArea";
import TextInput from "../components/TextInput";
import MultiCheckbox from "../components/MultiCheckbox";
import { INTERESTS } from "../helpers/formLists";
import HorizontalRule from "../components/HorizontalRule";

function ReferencesSelect({ control, setValue }) {
  return (
    <>
      <Heading fill level={2}>
        Joining Carriage House
      </Heading>
      <FormBlock
        title={
          "Please tell us how you heard about Carriage House and a little about yourself and why you would be a good fit for Carriage House:"
        }>
        <TextArea control={control} name="about_you" label={"About You"} />
      </FormBlock>
      <HorizontalRule />
      <Heading fill level={2}>
        Interests
      </Heading>
      <FormBlock title={"Please select any interests from the following:"}>
        <MultiCheckbox
          optional
          name={"interests"}
          setValue={setValue}
          control={control}
          options={INTERESTS}
          direction="column"
        />
         <TextInput
          optional
          control={control}
          name="other_interest"
          label={"Other"}
        />
      </FormBlock>
    </>
  );
}

export default ReferencesSelect;
