import React, { useState } from "react";
import { Paragraph, TextInput } from "grommet";
import styled from "styled-components";
import { Controller } from "react-hook-form";
import FormField from "./FormField";
import { useSelector } from "react-redux";

const StyledInput = styled.textarea`
  border: ${(props) => `1px solid ${props.error ? "#CE614A" : "#0030571f"}`};
  outline: ${(props) => (props.error ? "1px solid #CE614A" : "none")};
  padding: 21px 17px;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.5px;
  color: #0056b4;
  background: transparent;
  height: 100px;

  &:focus {
    outline: ${(props) => `2px solid ${props.error ? "#CE614A" : "#0056b4"}`};
  }
`;

function TextInputComponent({
  control,
  name,
  label,
  ...rest
}) {
  const defaultValue = useSelector((state) => state.form[name]);
  const [show, setShow] = useState(defaultValue ? true : false);

  return control ? (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required: true }}
      render={({
        field,
        fieldState: { invalid, isTouched, isDirty, error },
      }) => {
        console.log("VALUE", field.value);
        return (
          <FormField
            setShow={setShow}
            show={true}
            error={error}
            name={name}
            label={show ? label : null}
            {...rest}>
            <StyledInput
              error={error}
              placeholder={show ? null : label}
              {...field}>
              {field.value}
            </StyledInput>
          </FormField>
        );
      }}
    />
  ) : null;
}

export default TextInputComponent;
