import React, { useEffect, useState } from "react";
import { Box, CheckBox, FormField, Text } from "grommet";
import styled from "styled-components";
import { Controller, useController } from "react-hook-form";
import { useSelector } from "react-redux";

const StyledRadio = styled(Box)`
  border: 1px solid #0030571f;
  padding: 21px;
  width: auto;
  align-self: stretch;
  flex: 1;
  flex-direction: row;
`;

function MultiCheckboxComponent({
  name,
  label,
  options,
  info,
  direction = "column",
  control,
  setValue,
  optional = false
}) {
  const defaultValue = useSelector((state) => state.form[name]);
  const form = useSelector((state) => state.form);
  const [checked, setChecked] = useState(defaultValue);

  const {
    field: { ref, onChange, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: !optional },
    defaultValue: "",
  });

  useEffect(()=>{
    setValue(name, defaultValue)
  },[])

  const onValueChange = (e, option) => {
    let temp = [...checked];
    if (temp.includes(option)) {
      let newValues = temp.filter((item) => item !== option);
      setChecked(newValues);
      setValue(name, newValues, { shouldTouch: true });
    } else {
      temp.push(option);
      let uniq = [...new Set(temp)];
      setChecked(uniq);
      setValue(name, uniq, { shouldTouch: true });
    }
  };

  return (
    <>
      <Box
        style={{ border: `2px solid ${error ? "#CE614A" : "transparent"}` }}
        error={error}
        direction={direction}
        gap={direction === "column" ? "small" : "medium"}
        margin={{ top: "25px" }}>
        {options.map((option, index) => {
          // console.log(checked, option)
          return (
            <StyledRadio key={index}>
              <CheckBox
                name="radio"
                value={option}
                checked={checked && checked?.includes(option)}
                label={option}
                onChange={(event) => onValueChange(event, option)}
              />
            </StyledRadio>
          );
        })}
      </Box>
      {error && (
        <Text style={{ fontSize: 12, color: "#CE614A", margin: 0 }}>
          {"required"}
        </Text>
      )}
    </>
  );
}

export default MultiCheckboxComponent;
